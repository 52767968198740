import { usePrepareTemplate } from 'keycloakify/lib/usePrepareTemplate';
import { useGetClassName } from 'keycloakify/login/lib/useGetClassName';
import type { TemplateProps } from 'keycloakify/login/TemplateProps';
import { clsx } from 'keycloakify/tools/clsx';
import { useEffect } from 'react';

import { Alert } from '@neondatabase/glow';

import { useAnalytics } from './analytics';
import type { I18n, MessageKey } from './i18n';
import type { KcContext } from './kcContext';
import { PAGE_ID_MAPPING } from './pageIds';
import { GoToHomeButton } from './shared/GoToHomeButton';
import style from './Template.module.css';

function isPageIdValid(key: string): key is keyof typeof PAGE_ID_MAPPING {
  return key in PAGE_ID_MAPPING;
}

export const Template = (props: TemplateProps<KcContext, I18n>) => {
  const {
    displayInfo = false,
    displayMessage = true,
    headerNode,
    showUsernameNode = null,
    infoNode = null,
    kcContext,
    doUseDefaultCss,
    classes,
    i18n,
    children,
  } = props;

  const { getClassName } = useGetClassName({ doUseDefaultCss, classes });

  const { url, message, isAppInitiatedAction, pageId, auth } = kcContext;

  const { msg, msgStr } = i18n;

  // Set tab title
  const page = isPageIdValid(pageId) ? PAGE_ID_MAPPING[pageId] : pageId;
  const titleKey = `${page}_tab_title` as MessageKey;
  const title = msgStr(titleKey) ?? 'Neon';
  document.title = title;

  const { trackPage } = useAnalytics();
  useEffect(() => {
    const isError = message?.type === 'error';
    trackPage(page, isError);
  }, [trackPage, page, message]);

  const { isReady } = usePrepareTemplate({
    doFetchDefaultThemeResources: doUseDefaultCss,
    styles: [
      `${url.resourcesCommonPath}/node_modules/patternfly/dist/css/patternfly.min.css`,
      `${url.resourcesCommonPath}/node_modules/patternfly/dist/css/patternfly-additions.min.css`,
      `${url.resourcesCommonPath}/lib/zocial/zocial.css`,
      `${url.resourcesPath}/css/login.css`,
    ],
    htmlClassName: getClassName('kcHtmlClass'),
    bodyClassName: getClassName('kcBodyClass'),
  });

  if (!isReady) {
    return null;
  }

  const reauthenticating =
    auth !== undefined && auth.showUsername && !auth.showResetCredentials;

  return (
    <div className={style.root}>
      <div
        id="kc-header"
        className={clsx(getClassName('kcHeaderClass'), style.side)}
      >
        <div id="kc-header-wrapper" className={style.sideTitle}>
          Set up serverless Postgres in seconds.
        </div>
      </div>

      <div className={style.contentRoot}>
        <GoToHomeButton />
        <div className={style.content}>
          {pageId !== 'register.ftl' && <div className={style.mobileLogo} />}

          {reauthenticating ? (
            <>
              {showUsernameNode}
              <div className={getClassName('kcFormGroupClass')}>
                <div id="kc-username">
                  <label id="kc-attempted-username">
                    {auth?.attemptedUsername}
                  </label>
                  <a id="reset-login" href={url.loginRestartFlowUrl}>
                    <div className="kc-login-tooltip">
                      <i className="kcResetFlowIcon pficon pficon-arrow fa-md" />
                      <span className="kc-tooltip-text">
                        {msg('restartLoginTooltip')}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </>
          ) : (
            headerNode
          )}

          {/* App-initiated actions should not see warning messages about the need to complete the action during login. */}
          {displayMessage &&
            message !== undefined &&
            (message.type !== 'warning' || !isAppInitiatedAction) && (
              <Alert appearance={message.type} className={style.alert}>
                <span
                  className="kc-feedback-text"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: message.summary,
                  }}
                />
              </Alert>
            )}
          {children}
          {displayInfo && infoNode && (
            <div id="kc-info" className={getClassName('kcSignUpClass')}>
              <div
                id="kc-info-wrapper"
                className={getClassName('kcInfoAreaWrapperClass')}
              >
                {infoNode}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
